<template>
  <div class="recipes">
    <div class="card" style="background-color: #2d3748 !important">
      <div class="card-content" style="padding-left: 0px !important">
        <p style="color: white">Relatórios de faturamento</p>
      </div>
    </div>
    <b-field>
      <b-select
        expanded
        placeholder="Selecione um cliente"
        required
        @input="selectCustomer"
      >
        <option
          v-for="(item, index) in allCustomers"
          :key="index"
          :value="item"
        >
          {{ item.name }}
        </option>
      </b-select>
    </b-field>
    <div class="block">
      <b-switch @input="selectCustomer(selectedCustomer)" v-model="type">
        Mostrar %
      </b-switch>
    </div>
    <bar-chart v-if="!loading" :chartData="data"></bar-chart>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BarChart from '@/components/BarChart'
import service from '../features/financial-reports/store/service'

export default {
  name: 'FinancialChart',
  data() {
    return {
      showDetailIcon: false,
      services: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      servicesTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      loading: false,
      type: false,
      selectedCustomer: null,
      allTotalCustomers: [],
    }
  },
  components: {
    BarChart,
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('financialReports', ['allFinancialReports']),
    ...mapState('customers', ['allCustomers']),
  },
  methods: {
    ...mapActions('financialReports', ['getAllFinancialReports']),
    ...mapActions('customers', ['getAllCustomers']),
    async init() {
      await this.getAllFinancialReports()
      await this.getAllCustomers()

      this.selectCustomer(null)
    },
    async selectCustomer(customer) {
      this.selectedCustomer = customer
      this.loading = true
      this.services = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.servicesTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

      if (this.type) {
        const resultTotal = await service.getAllReportServicesByCustomerId(null)
        resultTotal.data.forEach((x, index) => {
          this.servicesTotal[index] += parseFloat(x.value).toFixed(2)
        })
      }

      const result = await service.getAllReportServicesByCustomerId(
        customer ? customer.id : null
      )
      console.log(result)

      result.data.forEach((x, index) => {
        if (this.type) {
          const total = this.servicesTotal[index]
          if (!customer && x.value > 0) {
            this.services[index] = 100
          } else {
            this.services[index] = this.getPercentageByValue(
              total,
              parseFloat(x.value).toFixed(2)
            )
          }
        } else {
          this.services[index] += parseFloat(x.value).toFixed(2)
        }
      })

      this.data = this.services
      this.loading = false
    },
    getPercentageByValue(total, value) {
      total = parseInt(total)
      value = parseInt(value)

      if (!total || total === 0 || !value || value === 0) {
        return 0
      }

      return ((value / total) * 100).toFixed(2)
    },
  },
}
</script>

<style scoped>
</style>
